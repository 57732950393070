import { type Dispatch, type SetStateAction } from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Collapse,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { type UseFormRegisterReturn, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { resetPassword, resetPasswordService } from '~/api'
import logo from '~/assets/header_logo.png'
import animationData from '~/assets/ilustracion_loading.json'
import left from '~/assets/left.png'
import right from '~/assets/right.png'
import { axios, serverHost } from '~/axios'
import PlatformLanguageSelector from '~/components/LanguageSelector'
import StrongPasswordCriterias from '~/components/shared/StrongPasswordCriterias'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { updateUser } from '~/redux/slices/user'
import { getToken } from '~/services/auth-services'
import { ROLES } from '~/utils/role-utils'
import { type Environment } from '~/utils/types/user'

import Lottie from './login/Lottie'

const loginSchema = z.object({
  username: z.string().trim().min(1),
  password: z.string().min(1),
})

const forgotPasswordSchema = z.object({
  username: z.string().trim().min(1),
  email: z.string().trim().min(1).toLowerCase(),
})

const resetPasswordSchema = z.object({
  newPassword: z.string().min(1),
  newPasswordConfirm: z.string().min(1),
})

type FormMode = 'login' | 'forgotPassword' | 'resetPassword'
type FormValues = z.infer<
  typeof loginSchema | typeof forgotPasswordSchema | typeof resetPasswordSchema
>
type FormField = keyof z.infer<
  typeof loginSchema & typeof forgotPasswordSchema & typeof resetPasswordSchema
>

const enviromentsList = ['platform', 'demo', 'testing'] as const
const websiteEnv: (typeof enviromentsList)[number] =
  enviromentsList.find(env => serverHost.includes(env)) ?? 'testing'

const mainPagesByRole = {
  [ROLES.SURVEY_TAKER]: '/surveys',
  [ROLES.FACILITATOR]: '/surveys',
  [ROLES.STAKEHOLDER]: '/solutions',
  [ROLES.FAMILY]: '/my-profile',
} as const

export default function Login() {
  const navigate = useNavigate()
  const location = useLocation()

  const { t } = useTranslation()

  const user = useAppSelector(state => state.user)

  const [formMode, setFormMode] = useState<FormMode>('login')
  const [errorMessage, setErrorMessage] = useState<null | string>(null)

  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const id = urlParams.get('id')

  const platform = websiteEnv === 'platform' ? 'demo' : 'platform'

  useEffect(() => {
    //* NOTE: If there's a token and an id, it means the user
    //* will reset its password
    if (token === null || id === null) return
    setFormMode('resetPassword')
  }, [token, id])

  useEffect(() => {
    if (errorMessage)
      setTimeout(() => {
        setErrorMessage('')
      }, 10000)
  }, [errorMessage])

  useEffect(() => {
    if (user?.token) return
    const targetURL = location.pathname

    if (targetURL === '/' || targetURL.includes('/login')) return

    navigate(`/login?returnURL=${targetURL}`, { replace: true })
  }, [location, navigate, user])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        overflowY: 'auto',
        minHeight: '100vh',
        backgroundColor: theme => theme.palette.background.paper,
      }}
    >
      {/* NOTE: Image Container */}
      <Stack flex={1} sx={{ display: { xs: 'none', lg: 'flex' } }}>
        <Stack
          flex={1}
          alignItems="center"
          justifyContent="center"
          sx={{
            overflowY: 'hidden',
            position: 'relative',
            borderTopRightRadius: 120,
            backgroundColor: theme => theme.palette.background.default,
          }}
        >
          <Box sx={{ height: 200, position: 'absolute', left: 0, top: -5 }}>
            <img src={left} alt="Banner Up" />
          </Box>

          <Lottie options={defaultOptions} height={650} width={650} />

          <Box sx={{ height: 20, position: 'absolute', right: 80 }}>
            <img src={right} alt="Banner Down" />
          </Box>

          <Box
            sx={{
              position: 'absolute',
              left: theme => theme.spacing(1),
              bottom: theme => theme.spacing(2),
            }}
          >
            <PlatformLanguageSelector withTitle />
          </Box>
        </Stack>
      </Stack>

      {/* NOTE: Login Form */}
      <Stack flex={1} sx={{ bgcolor: theme => theme.palette.background.paper }}>
        <Stack
          alignItems="end"
          sx={{ display: { xs: 'none', lg: 'flex' }, p: 2 }}
        >
          <img src={logo} width={50} height={50} alt="Stoplight Logo" />
        </Stack>

        <Stack flex={1} p={2} alignItems="center" justifyContent="center">
          <Box sx={{ maxWidth: 425 }}>
            <Stack
              flexDirection="row"
              justifyContent="center"
              sx={{
                py: 2,
                display: { xs: 'flex', lg: 'none' },
              }}
            >
              <img src={logo} width={75} height={75} alt="Stoplight Logo" />
            </Stack>

            <Typography
              variant="h4"
              fontSize={32}
              fontWeight={700}
              sx={{
                lineHeight: 1.2,
                textAlign: { xs: 'center', lg: 'start' },
                color: theme => theme.palette.primary.main,
              }}
            >
              {websiteEnv === 'platform'
                ? t('views.login.welcomeToPlatform')
                : t('views.login.welcomeToDemo')}
            </Typography>

            <Stack flexDirection="row" gap={0.5} sx={{ my: 1 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: theme => theme.palette.grey[500] }}
              >
                {t('views.login.switchTo')}
              </Typography>
              <Typography variant="subtitle2">
                <a href={`https://${platform}.povertystoplight.org/`}>
                  <Typography variant="subtitle2">{platform}</Typography>
                </a>
              </Typography>
            </Stack>

            <Collapse in={!!errorMessage}>
              <Alert
                severity="error"
                variant="filled"
                onClose={() => {
                  setErrorMessage('')
                }}
              >
                {errorMessage}
              </Alert>
            </Collapse>

            <Collapse in={formMode === 'forgotPassword'}>
              <Alert sx={{ borderRadius: 2 }} severity="info">
                {t('views.login.resetTitle')}
              </Alert>
            </Collapse>

            <LoginForm
              formMode={formMode}
              setFormMode={setFormMode}
              setErrorMessage={setErrorMessage}
            />
          </Box>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ position: 'relative' }}
        >
          <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
            <PlatformLanguageSelector withTitle />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  )
}

interface LoginFormProps {
  formMode: FormMode
  setFormMode: Dispatch<SetStateAction<FormMode>>
  setErrorMessage: Dispatch<SetStateAction<string | null>>
}

function LoginForm({ setErrorMessage, formMode, setFormMode }: LoginFormProps) {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const user = useAppSelector(state => state.user)

  const [isLoading, setIsLoading] = useState(false)
  const [isRepeatPasswordValid, setIsRepeatPasswordValid] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    setFocus,
    watch,
    formState: { errors },
  } = useForm<FormValues>({
    mode: 'onChange',
  })

  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const id = urlParams.get('id')

  function handleFormSublink() {
    if (formMode !== 'login') {
      setFormMode('login')
      return
    }
    if (!!token || !!id) {
      setFormMode('resetPassword')
      return
    }
    setFormMode('forgotPassword')
  }

  async function onSubmit(values: FormValues) {
    setIsLoading(true)
    const formData = new FormData()

    if (formMode === 'login') {
      const validationResult = loginSchema.safeParse(values)
      if (!validationResult.success) return

      const formValues = validationResult.data

      formData.set('username', formValues.username)
      formData.set('password', formValues.password)
      formData.set('grant_type', 'password')

      try {
        const response = await getToken(formData)

        const retrievedUser = response.data.user
        const userRole = retrievedUser.authorities[0].authority

        if (retrievedUser.didExpire) {
          enqueueSnackbar(t('views.login.passwordExpired'), {
            variant: 'error',
            persist: true,
          })

          setFormMode('forgotPassword')
          setFocus('email')

          return
        }

        if (retrievedUser.willExpire) {
          const message =
            retrievedUser.daysToExpirePassword === 1
              ? t('views.login.passwordWillExpire_one', {
                  count: retrievedUser.daysToExpirePassword,
                })
              : t('views.login.passwordWillExpire_other', {
                  count: retrievedUser.daysToExpirePassword,
                })

          enqueueSnackbar(message, {
            variant: 'passwordWarning',
            persist: true,
          })
        }

        dispatch(
          // @ts-expect-error Email should be `string` but comes as `string | undefined` for some reason
          updateUser({
            ...user,
            ...retrievedUser,
            token: response.data.access_token,
            refreshToken: response.data.refresh_token,
            env: response.data.env as Environment,
            willExpire: response.data.user.willExpire,
            didExpire: response.data.user.didExpire,
            daysToExpirePassword: response.data.user.daysToExpirePassword,
          }),
        )

        const targetURL = location.search
        const parsedURL = new URLSearchParams(targetURL).get('returnURL')
        const mainPageByRole = mainPagesByRole[userRole] ?? '/dashboard'

        navigate(parsedURL ?? mainPageByRole, { replace: true })
      } catch (error) {
        let errorMessage: null | string = null

        if (error.toString().includes('401'))
          errorMessage = t('views.login.wrongCredentials')
        if (error.toString().includes('403'))
          errorMessage = t('views.login.inactiveUser')
        if (errorMessage === null)
          errorMessage = t('views.login.connectionError')

        setErrorMessage(errorMessage)
      } finally {
        setIsLoading(false)
      }

      return
    }

    if (formMode === 'forgotPassword') {
      const validationResult = forgotPasswordSchema.safeParse(values)
      if (!validationResult.success) return

      const formValues = validationResult.data

      formData.set('email', formValues.email)
      formData.set('username', formValues.username)

      try {
        await resetPasswordService(formData)

        reset()
        enqueueSnackbar(t('views.login.mailResetSuccess'), {
          variant: 'success',
        })
      } catch (error) {
        setErrorMessage(t('views.login.mailNotFound'))
      } finally {
        setIsLoading(false)
      }

      return
    }

    if (formMode === 'resetPassword') {
      if (!token || !id) return

      const validationResult = resetPasswordSchema.safeParse(values)
      if (!validationResult.success) return

      const formValues = validationResult.data

      formData.set('userId', id)
      formData.set('token', token)
      formData.set('password', formValues.newPassword)
      formData.set('repeatPassword', formValues.newPasswordConfirm)

      try {
        await resetPassword(formData)

        enqueueSnackbar(t('views.login.passwordReseted'), {
          variant: 'success',
        })
        navigate('/login', { replace: true })
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setErrorMessage(error.response?.data.developerMessage)
          return
        }

        setErrorMessage(t('views.login.newPasswordConfirmFailed'))
      } finally {
        reset()
        setIsLoading(false)
      }
    }
  }

  return (
    <Stack gap={2} py={1} component="form" onSubmit={handleSubmit(onSubmit)}>
      {/* NOTE: Normal Login Mode */}
      {formMode === 'login' && (
        <>
          <LoginInput
            autofocus
            label={t('views.login.username')}
            register={register('username', { required: true })}
          />

          <LoginInput
            isPassword
            label={t('views.login.password')}
            register={register('password', { required: true })}
          />
        </>
      )}

      {/* NOTE: Forgot Password Mode */}
      {formMode === 'forgotPassword' && (
        <>
          <LoginInput
            label={t('views.login.username')}
            register={register('username', { required: true })}
          />

          <LoginInput
            label={t('views.login.email')}
            register={register('email', { required: true })}
          />
        </>
      )}

      {formMode === 'resetPassword' && (
        <>
          <LoginInput
            isPassword
            label={t('views.login.newPassword')}
            register={register('newPassword', { required: true })}
          />

          <LoginInput
            isPassword
            label={t('views.login.newPasswordConfirm')}
            errorMessage={
              'newPasswordConfirm' in errors
                ? errors.newPasswordConfirm?.message
                : null
            }
            register={register('newPasswordConfirm', {
              validate: async (value, formValues) => {
                if (!isRepeatPasswordValid) return !isRepeatPasswordValid
                const passwordConfirmation =
                  'newPassword' in formValues ? formValues.newPassword : null

                if (value !== passwordConfirmation) {
                  return t('views.user.form.passwordConfirmFailed')
                }
              },
            })}
          />
          <Collapse in={Boolean(watch('newPasswordConfirm'))}>
            <StrongPasswordCriterias
              password={watch('newPasswordConfirm')}
              setIsPasswordValid={setIsRepeatPasswordValid}
            />
          </Collapse>
        </>
      )}

      <Typography
        sx={{
          fontSize: 14,
          cursor: 'pointer',
          display: 'inline',
          textAlign: 'right',
          textDecoration: 'underline',
          color: theme => theme.palette.primary.dark,
        }}
        onClick={() => {
          handleFormSublink()
        }}
      >
        {formMode === 'login'
          ? t('views.login.forgotPassword')
          : t('views.login.backLogin')}
      </Typography>

      <LoadingButton
        sx={{ py: 1 }}
        type="submit"
        loading={isLoading}
        variant="contained"
      >
        <Typography fontSize={18} fontWeight={500}>
          {formMode === 'login'
            ? t('views.login.login')
            : t('views.login.resetPassword')}
        </Typography>
      </LoadingButton>
    </Stack>
  )
}

interface LoginInputProps {
  label: string
  autofocus?: boolean
  isPassword?: boolean
  errorMessage?: string | null
  register: UseFormRegisterReturn<FormField>
}

function LoginInput({
  label,
  register,
  errorMessage,
  autofocus = false,
  isPassword = false,
}: LoginInputProps) {
  const [shouldShowPassword, setShouldShowPassword] = useState(isPassword)

  return (
    <Box>
      <TextField
        fullWidth
        label={label}
        autoFocus={autofocus}
        helperText={errorMessage}
        error={Boolean(errorMessage)}
        type={shouldShowPassword ? 'password' : 'text'}
        {...register}
        InputProps={{
          sx: {
            '& fieldset': { border: 'none' },
            bgcolor: theme => theme.palette.background.default,
          },
          endAdornment: isPassword && (
            <InputAdornment position="end">
              <IconButton
                size="large"
                onClick={() => {
                  setShouldShowPassword(prev => !prev)
                }}
              >
                {shouldShowPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  )
}
