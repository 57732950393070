import { Fragment, type PropsWithChildren } from 'react'

import { Close as CloseIcon } from '@mui/icons-material'
import { Button, IconButton, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Form, Formik } from 'formik'
import { camelCase, startCase } from 'lodash'
import { useSnackbar } from 'notistack'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'

import AudioHelp from '~/components/AudioHelp'
import AutocompleteWithFormik from '~/components/AutocompleteWithFormik'
import BottomSpacer from '~/components/BottomSpacer'
import CheckboxWithFormik from '~/components/CheckboxWithFormik'
import Container from '~/components/Container'
import InputWithDep from '~/components/InputWithDep'
import InputWithFormik from '~/components/InputWithFormik'
import RadioWithFormik from '~/components/RadioWithFormik'
import { withScroller } from '~/components/Scroller'
import TitleBar from '~/components/TitleBar'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { setTimersByScreen, updateDraft } from '~/redux/slices/currentDraft'
import {
  normalizeDraftForSnapshot,
  saveSnapshotDraft,
} from '~/services/lifemap-survey-services'
import {
  familyMemberWillHaveQuestions,
  getConditionalOptions,
  getDraftWithUpdatedEconomic,
  getDraftWithUpdatedFamilyEconomics,
  getDraftWithUpdatedQuestionsCascading,
  shouldShowQuestion,
} from '~/utils/conditional-logic'
import { screenValidation } from '~/utils/survey-utils'
import { type CurrentDraft } from '~/utils/types/current-draft'
import {
  type CurrentSurvey,
  type SurveyEconomicQuestion,
} from '~/utils/types/current-survey'

import EconomicIntroduction from './EconomicIntroduction'

const useFamilyMemberStyles = makeStyles(_theme => ({
  familyMemberNameLarge: {
    marginTop: '15px',
    marginBottom: '-10px',
    fontSize: '23px',
  },
  familyMemberTypography: {
    marginTop: '20px',
    marginBottom: '16px',
  },
  familyMemberTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  familyMemberIcon: {
    marginRight: 7,
    fontSize: 35,
    color: '#909090',
  },
}))

function FamilyMemberTitle({ children }: PropsWithChildren<object>) {
  const classes = useFamilyMemberStyles()

  return (
    <div className={classes.familyMemberNameLarge}>
      <Typography className={classes.familyMemberTypography} variant="h6">
        <span className={classes.familyMemberTitle}>
          <i className={`material-icons ${classes.familyMemberIcon}`}>face</i>
          {children}
        </span>
      </Typography>
    </div>
  )
}

function capitalize(text: string) {
  return startCase(text).replace(/ /g, '')
}

function buildValidationForField(question: SurveyEconomicQuestion) {
  let validation = Yup.string()
  if (question.required) {
    validation = validation.required('validation.fieldIsRequired')
  }
  return validation
}

interface Questions {
  forFamily: CurrentSurvey['surveyEconomicQuestions']
  forFamilyMember: CurrentSurvey['surveyEconomicQuestions']
}

/**
 * Builds the validation schema that will be used by Formik
 * @param questions The list of economic questions for the current screen
 * @param currentDraft the current draft from redux state
 */
function buildValidationSchemaForQuestions(
  questions: Questions,
  currentDraft: CurrentDraft,
) {
  const forFamilySchema = {}
  const familyQuestions = questions?.forFamily || []

  familyQuestions.forEach(question => {
    if (shouldShowQuestion(question, currentDraft)) {
      forFamilySchema[question.codeName] = buildValidationForField(question)
    }
  })

  const forFamilyMemberSchema = {}
  const familyMemberQuestions = questions?.forFamilyMember || []
  const familyMembersList = currentDraft.familyData.familyMembersList ?? []

  familyMembersList.forEach((_member, index) => {
    const memberScheme = {}
    familyMemberQuestions.forEach(question => {
      if (shouldShowQuestion(question, currentDraft, index)) {
        memberScheme[question.codeName] = buildValidationForField(question)
      }
    })
    forFamilyMemberSchema[index] = Yup.object().shape({
      ...memberScheme,
    })
  })
  const validationSchema = Yup.object().shape({
    forFamily: Yup.object().shape(forFamilySchema),
    forFamilyMember: Yup.object().shape(forFamilyMemberSchema),
  })
  return validationSchema
}

/**
 * Based on the current draft, builds the initial values of the economics section
 * @param questions The list of economic questions for the current screen
 * @param currentDraft the current draft from redux state
 */
function buildInitialValuesForForm(questions, currentDraft: CurrentDraft) {
  const forFamilyInitial = {}
  const familyQuestions = questions?.forFamily || []

  familyQuestions.forEach(question => {
    const draftQuestion =
      currentDraft.economicSurveyDataList.find(
        e => e.key === question.codeName,
      ) ?? null

    if (question.options.find(o => o.otherOption)) {
      forFamilyInitial[`custom${capitalize(question.codeName)}`] =
        draftQuestion?.other ?? ''
    }

    forFamilyInitial[question.codeName] =
      draftQuestion?.value ?? draftQuestion?.multipleValue ?? ''
  })

  const forFamilyMemberInitial = {}
  const familyMemberQuestions = questions?.forFamilyMember || []
  const familyMembersList = currentDraft.familyData.familyMembersList ?? []
  familyMembersList.forEach((familyMember, index) => {
    const memberInitial = {}
    const socioEconomicAnswers = familyMember.socioEconomicAnswers ?? []
    familyMemberQuestions.forEach(question => {
      const draftQuestion =
        socioEconomicAnswers.find(e => e.key === question.codeName) || null

      if (question.options.find(o => o.otherOption)) {
        memberInitial[`custom${capitalize(question.codeName)}`] =
          draftQuestion?.other ?? ''
      }

      memberInitial[question.codeName] =
        draftQuestion?.value ?? draftQuestion?.multipleValue ?? ''
    })
    forFamilyMemberInitial[index] = memberInitial
  })

  return {
    forFamily: forFamilyInitial,
    forFamilyMember: forFamilyMemberInitial,
  }
}

function Economics({ scrollToTop }: { scrollToTop: () => void }) {
  const [questions, setQuestions] = useState<Questions | null>(null)
  const [initialized, setInitialized] = useState(false)
  const [topic, setTopic] = useState('')
  const [initialValues, setInitialValues] = useState<Questions | object>({})
  const [playTopicAudio, setPlayTopicAudio] = useState(false)

  const params = useParams<{ page: string }>()
  const dispatch = useAppDispatch()
  const classes = useStyles()
  const navigate = useNavigate()

  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })
  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraft')
    return state.currentDraft
  })
  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const pageId = Number(params.page)

  function handleContinue(shouldReplace = false) {
    if (!currentSurvey.economicScreens) throw new Error('No economicScreens')

    const normalizedDraft = normalizeDraftForSnapshot(currentDraft)
    void saveSnapshotDraft(normalizedDraft)

    if (pageId < currentSurvey.economicScreens.questionsPerScreen.length - 1) {
      if (shouldReplace) {
        navigate(`/lifemap/economics/${pageId + 1}`, {
          replace: true,
        })
      } else {
        navigate(`/lifemap/economics/${pageId + 1}`)
      }
    } else if (shouldReplace) {
      navigate('/lifemap/begin-stoplight', {
        replace: true,
      })
    } else {
      navigate('/lifemap/begin-stoplight')
    }
  }

  function setCurrentScreen() {
    if (!currentSurvey.economicScreens) throw new Error('No economicScreens')

    const currentScreenQuestions =
      currentSurvey.economicScreens.questionsPerScreen[pageId]

    // If we only have family members questions, we have to validate
    // if we'll show at least one question for at least one of the
    // members. Otherwise, we should just go to the next page
    if (
      !currentScreenQuestions.forFamily.length &&
      currentScreenQuestions.forFamilyMember.length
    ) {
      const { familyMembersList } = structuredClone(currentDraft.familyData)
      let atLeastOneMemberHasQuestions = false

      familyMembersList.forEach((_member, idx) => {
        atLeastOneMemberHasQuestions =
          atLeastOneMemberHasQuestions ||
          familyMemberWillHaveQuestions(
            currentScreenQuestions,
            currentDraft,
            idx,
          )
      })
      if (!atLeastOneMemberHasQuestions) {
        handleContinue(true)
      }
    }
    setQuestions(currentScreenQuestions)
    setTopic(
      currentScreenQuestions.forFamily.length
        ? currentScreenQuestions.forFamily[0].topic
        : currentScreenQuestions.forFamilyMember[0].topic,
    )
    setInitialValues(
      buildInitialValuesForForm(currentScreenQuestions, currentDraft),
    )
    setInitialized(true)
    setPlayTopicAudio(false)
  }

  useEffect(() => {
    const validationResult = screenValidation(
      currentDraft,
      'ECONOMIC',
      currentSurvey,
    )

    if (!validationResult) {
      return
    }

    enqueueSnackbar(t('validation.completionRequired'), { variant: 'error' })
    navigate(validationResult)
    dispatch(setTimersByScreen('SocioEconomicQuestion'))
    setCurrentScreen()
  }, [currentDraft, currentSurvey, dispatch, enqueueSnackbar, navigate, t])

  useEffect(() => {
    dispatch(setTimersByScreen('SocioEconomicQuestion'))

    setInitialized(false)
    setCurrentScreen()
  }, [dispatch, pageId])

  function isQuestionInCurrentScreen(question: SurveyEconomicQuestion) {
    if (!questions) throw new Error('No questions')

    const lookIn = question.forFamilyMember
      ? questions.forFamilyMember
      : questions.forFamily

    return lookIn.some(q => q.codeName === question.codeName)
  }

  function updateEconomicAnswerCascading(
    question: SurveyEconomicQuestion,
    value: string,
    setFieldValue: (...args: any[]) => any,
    memberIndex?: number,
    shouldValidate = false,
  ) {
    const draftFromProps = structuredClone(currentDraft)

    const {
      conditionalQuestions,
      elementsWithConditionsOnThem: { questionsWithConditionsOnThem },
    } = currentSurvey

    const otherQuestion = question.codeName.match(/^custom/g)

    // We get a draft with updated answer
    let key = question.codeName
    let currentPageDraft: CurrentDraft
    let keyName = 'value'
    let hasOtherValue: boolean = false

    if (Array.isArray(value)) {
      keyName = 'multipleValue'
      hasOtherValue = !!question.options.find(o => o.otherOption)
    }

    let newAnswer = { key, [keyName]: value }
    let selectedValues: string | undefined

    const answers = !question.forFamilyMember
      ? draftFromProps.economicSurveyDataList
      : // NOTE: Using non-null assertion to keep at least the signature `any[]`. Without it, the signature is just `any`.
        draftFromProps.familyData.familyMembersList[memberIndex!]
          .socioEconomicAnswers!

    let answer = (answers ?? []).find(ans => ans.key === key)

    if (otherQuestion) {
      key = camelCase(question.codeName.replace(/^custom/g, ''))
      answer = answers.find(ans => ans.key === key)

      if (question.answerType === 'checkbox') {
        selectedValues = answer ? answer.multipleValue : []
      } else {
        selectedValues = question.options.find(o => o.otherOption)?.value
      }

      keyName = !Array.isArray(selectedValues) ? 'value' : 'multipleValue'

      // @ts-expect-error - selectedValues is `string | undefined` and we're expecting `string`
      newAnswer = {
        key,
        [keyName]: selectedValues,
        other: value,
      }
    }

    if (hasOtherValue && !otherQuestion && !!answer) {
      newAnswer = {
        key,
        [keyName]: value,
        other: answer.other,
      }
    }

    if (question.forFamilyMember) {
      currentPageDraft = getDraftWithUpdatedFamilyEconomics(
        draftFromProps,
        newAnswer,
        // @ts-expect-error - memberIndex is `number | undefined` and we're expecting `number`
        memberIndex,
      )
    } else {
      currentPageDraft = getDraftWithUpdatedEconomic(draftFromProps, newAnswer)
    }

    function cleanUpHook(
      conditionalQuestion: SurveyEconomicQuestion,
      index: number,
    ) {
      // Cleanup value from form that won't be displayed
      if (conditionalQuestion.forFamilyMember) {
        if (isQuestionInCurrentScreen(conditionalQuestion)) {
          setFieldValue(
            `forFamilyMember.[${index}].[${conditionalQuestion.codeName}]`,
            '',
            shouldValidate,
          )
        }
      } else if (isQuestionInCurrentScreen(conditionalQuestion)) {
        setFieldValue(
          `forFamily.[${conditionalQuestion.codeName}]`,
          '',
          shouldValidate,
        )
      }
    }

    // If the question has some conditionals on it,
    // execute function that builds a new draft with cascaded clean up
    // applied
    if (questionsWithConditionsOnThem.includes(question.codeName)) {
      console.log(
        `Will evaluate cascading after updating ${question.codeName} on member ${memberIndex}`,
      )
      currentPageDraft = getDraftWithUpdatedQuestionsCascading(
        currentPageDraft,
        conditionalQuestions.filter(
          conditionalQuestion =>
            conditionalQuestion.codeName !== question.codeName,
        ),
        cleanUpHook,
      )
    }

    // Updating formik value for the question that triggered everything
    if (question.forFamilyMember) {
      setFieldValue(
        `forFamilyMember.[${memberIndex}].[${question.codeName}]`,
        value,
      )
    } else {
      setFieldValue(`forFamily.[${question.codeName}]`, value)
    }

    dispatch(updateDraft(currentPageDraft))
  }

  let topicAudio = null
  if (questions?.forFamily.length) {
    topicAudio = questions.forFamily[0]
      ? questions.forFamily[0].topicAudio
      : null
  }
  if (questions?.forFamilyMember.length) {
    topicAudio = questions.forFamilyMember[0]
      ? questions.forFamilyMember[0].topicAudio
      : null
  }

  if (!initialized) {
    return <TitleBar title={topic} />
  }

  return (
    <>
      <TitleBar title={topic} progressBar />
      <div className={classes.mainContainer}>
        <Container variant="slim">
          {user.interactive_help && !!topicAudio && (
            <AudioHelp
              audio={topicAudio}
              playAudio={playTopicAudio}
              handlePlayPause={() => {
                setPlayTopicAudio(prev => !prev)
              }}
            />
          )}

          {questions && (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={buildValidationSchemaForQuestions(
                questions,
                currentDraft,
              )}
              onSubmit={(_values, { setSubmitting }) => {
                setSubmitting(false)
                setPlayTopicAudio(false)
                handleContinue()
              }}
            >
              {({ isSubmitting, setFieldValue, validateForm }) => (
                <Form noValidate>
                  <>
                    {/* List of questions for current topic */}
                    {!!questions.forFamily.length &&
                      questions.forFamily.map(question => {
                        const hasOtherOption = question.options.find(
                          o => o.otherOption,
                        )

                        const modifiedQuestion = {
                          ...question,
                          codeName: `custom${capitalize(question.codeName)}`,
                        }

                        function cleanUp(value: string) {
                          if (!hasOtherOption)
                            updateEconomicAnswerCascading(
                              modifiedQuestion,
                              '',
                              setFieldValue,
                            )
                          updateEconomicAnswerCascading(
                            question,
                            value,
                            setFieldValue,
                          )
                        }

                        function cleanUpMultipleValue() {
                          updateEconomicAnswerCascading(
                            modifiedQuestion,
                            '',
                            setFieldValue,
                          )
                        }

                        if (!shouldShowQuestion(question, currentDraft)) {
                          return <Fragment key={question.codeName} />
                        }

                        if (question.answerType === 'select') {
                          return (
                            <Fragment key={question.codeName}>
                              <EconomicIntroduction
                                introduction={question.introduction}
                              />
                              {/* @ts-expect-error missing props */}
                              <AutocompleteWithFormik
                                label={question.questionText}
                                name={`forFamily.[${question.codeName}]`}
                                rawOptions={getConditionalOptions(
                                  question,
                                  currentDraft,
                                )}
                                labelKey="text"
                                valueKey="value"
                                required={question.required}
                                isClearable={!question.required}
                                onChange={value => {
                                  updateEconomicAnswerCascading(
                                    question,
                                    value ? value.value : '',
                                    setFieldValue,
                                  )
                                }}
                              />
                              {/* @ts-expect-error missing props */}
                              <InputWithDep
                                key={`custom${capitalize(question.codeName)}`}
                                dep={question.codeName}
                                from={currentDraft}
                                fieldOptions={question.options}
                                target={`custom${capitalize(question.codeName)}`}
                                isEconomic
                                cleanUp={cleanUp}
                              >
                                {(otherOption, value) =>
                                  otherOption === value && (
                                    <InputWithFormik
                                      key={`custom${capitalize(
                                        question.codeName,
                                      )}`}
                                      type="text"
                                      label={t('views.survey.specifyOther')}
                                      name={`forFamily.custom${capitalize(
                                        question.codeName,
                                      )}`}
                                      // @ts-expect-error key doesn't exist in the props type
                                      onChange={e => {
                                        updateEconomicAnswerCascading(
                                          modifiedQuestion,
                                          e.target.value ?? '',
                                          setFieldValue,
                                        )
                                      }}
                                    />
                                  )
                                }
                              </InputWithDep>
                            </Fragment>
                          )
                        }
                        if (question.answerType === 'radio') {
                          return (
                            <Fragment key={question.codeName}>
                              <EconomicIntroduction
                                introduction={question.introduction}
                              />
                              <RadioWithFormik
                                label={question.questionText}
                                rawOptions={getConditionalOptions(
                                  question,
                                  currentDraft,
                                )}
                                key={question.codeName}
                                name={`forFamily.[${question.codeName}]`}
                                required={question.required}
                                onChange={e => {
                                  updateEconomicAnswerCascading(
                                    question,
                                    e.target.value ?? '',
                                    setFieldValue,
                                  )
                                }}
                              />
                              {/* @ts-expect-error missing props */}
                              <InputWithDep
                                key={`custom${capitalize(question.codeName)}`}
                                dep={question.codeName}
                                from={currentDraft}
                                fieldOptions={question.options}
                                target={`custom${capitalize(question.codeName)}`}
                                isEconomic
                                cleanUp={cleanUp}
                              >
                                {(otherOption, value) =>
                                  otherOption === value && (
                                    <InputWithFormik
                                      key={`custom${capitalize(
                                        question.codeName,
                                      )}`}
                                      type="text"
                                      label={t('views.survey.specifyOther')}
                                      name={`forFamily.custom${capitalize(
                                        question.codeName,
                                      )}`}
                                      // @ts-expect-error key doesn't exist in the props type
                                      onChange={e => {
                                        updateEconomicAnswerCascading(
                                          modifiedQuestion,
                                          e.target.value ?? '',
                                          setFieldValue,
                                        )
                                      }}
                                    />
                                  )
                                }
                              </InputWithDep>
                            </Fragment>
                          )
                        }
                        if (question.answerType === 'checkbox') {
                          return (
                            <Fragment key={question.codeName}>
                              <EconomicIntroduction
                                introduction={question.introduction}
                              />
                              <CheckboxWithFormik
                                key={question.codeName}
                                label={question.questionText}
                                rawOptions={getConditionalOptions(
                                  question,
                                  currentDraft,
                                )}
                                name={`forFamily.[${question.codeName}]`}
                                required={question.required}
                                onChange={multipleValue => {
                                  updateEconomicAnswerCascading(
                                    question,
                                    multipleValue,
                                    setFieldValue,
                                  )
                                }}
                              />
                              {/* @ts-expect-error missing props */}
                              <InputWithDep
                                key={`custom${capitalize(question.codeName)}`}
                                dep={question.codeName}
                                from={currentDraft}
                                fieldOptions={question.options}
                                target={`custom${capitalize(question.codeName)}`}
                                isEconomic
                                isMultiValue
                                cleanUp={cleanUpMultipleValue}
                              >
                                {(otherOption, value) =>
                                  otherOption === value && (
                                    <InputWithFormik
                                      key={`custom${capitalize(
                                        question.codeName,
                                      )}`}
                                      type="text"
                                      label={t('views.survey.specifyOther')}
                                      name={`forFamily.custom${capitalize(
                                        question.codeName,
                                      )}`}
                                      // @ts-expect-error key doesn't exist in the props type
                                      onChange={e => {
                                        updateEconomicAnswerCascading(
                                          modifiedQuestion,
                                          e.target.value ?? '',
                                          setFieldValue,
                                        )
                                      }}
                                    />
                                  )
                                }
                              </InputWithDep>
                            </Fragment>
                          )
                        }

                        return (
                          <Fragment key={question.codeName}>
                            <EconomicIntroduction
                              introduction={question.introduction}
                            />
                            <InputWithFormik
                              key={question.codeName}
                              label={question.questionText}
                              required={question.required}
                              name={`forFamily.[${question.codeName}]`}
                              type={
                                question.answerType === 'string'
                                  ? 'text'
                                  : question.answerType
                              }
                              // @ts-expect-error key doesn't exist in the props type
                              onChange={e => {
                                updateEconomicAnswerCascading(
                                  question,
                                  e.target.value ?? '',
                                  setFieldValue,
                                )
                              }}
                            />
                          </Fragment>
                        )
                      })}

                    {!!questions.forFamilyMember.length && (
                      <>
                        {currentDraft.familyData.familyMembersList.map(
                          (familyMember, index) => {
                            const willShowQuestions =
                              familyMemberWillHaveQuestions(
                                questions,
                                currentDraft,
                                index,
                              )
                            if (!willShowQuestions) {
                              return <Fragment key={familyMember.firstName} />
                            }
                            return (
                              <Fragment key={familyMember.firstName}>
                                <FamilyMemberTitle>
                                  {familyMember.firstName}{' '}
                                  {familyMember.lastName}
                                </FamilyMemberTitle>

                                <Fragment>
                                  {questions.forFamilyMember.map(question => {
                                    const modifiedQuestion = {
                                      ...question,
                                      codeName: `custom${capitalize(
                                        question.codeName,
                                      )}`,
                                    }

                                    const cleanUp = (value: string) => {
                                      updateEconomicAnswerCascading(
                                        modifiedQuestion,
                                        '',
                                        setFieldValue,
                                        index,
                                        false,
                                      )
                                      updateEconomicAnswerCascading(
                                        question,
                                        value,
                                        setFieldValue,
                                        index,
                                      )
                                    }
                                    const cleanUpMultipleValue = () => {
                                      updateEconomicAnswerCascading(
                                        modifiedQuestion,
                                        '',
                                        setFieldValue,
                                        index || 0,
                                      )
                                    }
                                    if (
                                      !shouldShowQuestion(
                                        question,
                                        currentDraft,
                                        index,
                                      )
                                    ) {
                                      return (
                                        <Fragment key={question.codeName} />
                                      )
                                    }
                                    if (question.answerType === 'select') {
                                      return (
                                        <Fragment key={question.codeName}>
                                          <EconomicIntroduction
                                            introduction={question.introduction}
                                          />
                                          {/* @ts-expect-error missing props */}
                                          <AutocompleteWithFormik
                                            label={question.questionText}
                                            name={`forFamilyMember.[${index}].[${question.codeName}]`}
                                            rawOptions={getConditionalOptions(
                                              question,
                                              currentDraft,
                                              index,
                                            )}
                                            labelKey="text"
                                            valueKey="value"
                                            required={question.required}
                                            isClearable={!question.required}
                                            onChange={value => {
                                              updateEconomicAnswerCascading(
                                                question,
                                                value ? value.value : '',
                                                setFieldValue,
                                                index,
                                              )
                                            }}
                                          />
                                          {/* @ts-expect-error missing props */}
                                          <InputWithDep
                                            key={`custom${capitalize(
                                              question.codeName,
                                            )}`}
                                            dep={question.codeName}
                                            index={index || 0}
                                            from={currentDraft}
                                            fieldOptions={question.options}
                                            isEconomic
                                            target={`forFamilyMember.[${index}].[custom${capitalize(
                                              question.codeName,
                                            )}]`}
                                            cleanUp={cleanUp}
                                          >
                                            {(otherOption, value) =>
                                              otherOption === value && (
                                                <InputWithFormik
                                                  type="text"
                                                  label={t(
                                                    'views.survey.specifyOther',
                                                  )}
                                                  name={`forFamilyMember.[${index}].[custom${capitalize(
                                                    question.codeName,
                                                  )}]`}
                                                  // @ts-expect-error key doesn't exist in the props type
                                                  onChange={e => {
                                                    updateEconomicAnswerCascading(
                                                      modifiedQuestion,
                                                      e.target.value ?? '',
                                                      setFieldValue,
                                                      index,
                                                    )
                                                  }}
                                                />
                                              )
                                            }
                                          </InputWithDep>
                                        </Fragment>
                                      )
                                    }
                                    if (question.answerType === 'radio') {
                                      return (
                                        <Fragment key={question.codeName}>
                                          <EconomicIntroduction
                                            introduction={question.introduction}
                                          />
                                          <RadioWithFormik
                                            label={question.questionText}
                                            name={`forFamilyMember.[${index}].[${question.codeName}]`}
                                            rawOptions={getConditionalOptions(
                                              question,
                                              currentDraft,
                                              index,
                                            )}
                                            required={question.required}
                                            onChange={e => {
                                              updateEconomicAnswerCascading(
                                                question,
                                                e.target.value ?? '',
                                                setFieldValue,
                                                index,
                                              )
                                            }}
                                          />
                                          {/* @ts-expect-error missing props */}
                                          <InputWithDep
                                            key={`custom${capitalize(
                                              question.codeName,
                                            )}`}
                                            dep={question.codeName}
                                            index={index || 0}
                                            from={currentDraft}
                                            fieldOptions={question.options}
                                            isEconomic
                                            target={`forFamilyMember.[${index}].[custom${capitalize(
                                              question.codeName,
                                            )}]`}
                                            cleanUp={cleanUp}
                                          >
                                            {(otherOption, value) =>
                                              otherOption === value && (
                                                <InputWithFormik
                                                  type="text"
                                                  label={t(
                                                    'views.survey.specifyOther',
                                                  )}
                                                  name={`forFamilyMember.[${index}].[custom${capitalize(
                                                    question.codeName,
                                                  )}]`}
                                                  // @ts-expect-error key doesn't exist in the props type
                                                  onChange={e => {
                                                    updateEconomicAnswerCascading(
                                                      modifiedQuestion,
                                                      e.target.value ?? '',
                                                      setFieldValue,
                                                      index,
                                                    )
                                                  }}
                                                />
                                              )
                                            }
                                          </InputWithDep>
                                        </Fragment>
                                      )
                                    }
                                    if (question.answerType === 'checkbox') {
                                      return (
                                        <Fragment key={question.codeName}>
                                          <EconomicIntroduction
                                            introduction={question.introduction}
                                          />
                                          <CheckboxWithFormik
                                            key={question.codeName}
                                            label={question.questionText}
                                            name={`forFamilyMember.[${index}].[${question.codeName}]`}
                                            rawOptions={getConditionalOptions(
                                              question,
                                              currentDraft,
                                              index,
                                            )}
                                            required={question.required}
                                            onChange={multipleValue => {
                                              updateEconomicAnswerCascading(
                                                question,
                                                multipleValue,
                                                setFieldValue,
                                                index,
                                              )
                                            }}
                                          />
                                          {/* @ts-expect-error missing props */}
                                          <InputWithDep
                                            key={`custom${capitalize(
                                              question.codeName,
                                            )}`}
                                            dep={question.codeName}
                                            index={index || 0}
                                            from={currentDraft}
                                            fieldOptions={question.options}
                                            isEconomic
                                            isMultiValue
                                            target={`forFamilyMember.[${index}].[custom${capitalize(
                                              question.codeName,
                                            )}]`}
                                            cleanUp={cleanUpMultipleValue}
                                          >
                                            {(otherOption, value) =>
                                              otherOption === value && (
                                                <InputWithFormik
                                                  type="text"
                                                  label={t(
                                                    'views.survey.specifyOther',
                                                  )}
                                                  name={`forFamilyMember.[${index}].[custom${capitalize(
                                                    question.codeName,
                                                  )}]`}
                                                  // @ts-expect-error key doesn't exist in the props type
                                                  onChange={e => {
                                                    updateEconomicAnswerCascading(
                                                      modifiedQuestion,
                                                      e.target.value ?? '',
                                                      setFieldValue,
                                                      index,
                                                    )
                                                  }}
                                                />
                                              )
                                            }
                                          </InputWithDep>
                                        </Fragment>
                                      )
                                    }
                                    return (
                                      <Fragment key={question.codeName}>
                                        <EconomicIntroduction
                                          introduction={question.introduction}
                                        />
                                        <InputWithFormik
                                          key={question.codeName}
                                          label={question.questionText}
                                          type={
                                            question.answerType === 'string'
                                              ? 'text'
                                              : question.answerType
                                          }
                                          name={`forFamilyMember.[${index}].[${question.codeName}]`}
                                          required={question.required}
                                          // @ts-expect-error key doesn't exist in the props type
                                          onChange={e => {
                                            updateEconomicAnswerCascading(
                                              question,
                                              e.target.value ?? '',
                                              setFieldValue,
                                              index,
                                            )
                                          }}
                                        />
                                      </Fragment>
                                    )
                                  })}
                                </Fragment>
                              </Fragment>
                            )
                          },
                        )}
                      </>
                    )}
                    <div className={classes.buttonContainerForm}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        test-id="continue"
                        disabled={isSubmitting}
                        onClick={() => {
                          void validateForm().then(validationErrors => {
                            if (!('forFamily' in validationErrors)) {
                              return
                            }

                            const forFamilyErrors =
                              validationErrors.forFamily ?? {}

                            const forFamilyErrorsCount =
                              Object.keys(forFamilyErrors).length

                            const forFamilyMemberErrors =
                              validationErrors.forFamilyMember ?? []

                            let forFamilyMemberErrorsCount = 0
                            if (typeof forFamilyMemberErrors === 'string')
                              throw new Error(
                                'forFamilyMemberErrors is not a string',
                              )
                            forFamilyMemberErrors.forEach(fm => {
                              forFamilyMemberErrorsCount += Object.keys(
                                fm || {},
                              ).length
                            })

                            const errorsLength =
                              forFamilyErrorsCount + forFamilyMemberErrorsCount

                            if (errorsLength) {
                              enqueueSnackbar(
                                t('views.family.formWithErrors', {
                                  count: errorsLength,
                                }),
                                {
                                  variant: 'error',
                                  action: key => (
                                    <IconButton
                                      key="dismiss"
                                      onClick={() => {
                                        closeSnackbar(key)
                                      }}
                                    >
                                      <CloseIcon style={{ color: 'white' }} />
                                    </IconButton>
                                  ),
                                },
                              )
                              scrollToTop()
                            }
                          })
                        }}
                      >
                        {t('general.continue')}
                      </Button>
                    </div>
                  </>
                </Form>
              )}
            </Formik>
          )}
        </Container>
        <BottomSpacer />
      </div>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  buttonContainerForm: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
  },
  mainContainer: {
    marginTop: theme.spacing(5),
  },
  playerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 10,
    marginTop: 10,
  },
  progressBar: {
    marginLeft: 10,
    width: '100%',
    backgroundColor: '#d8d8d8',
  },
  icon: {
    color: 'green',
    cursor: 'pointer',
    fontSize: 30,
  },
  audioHelp: {
    marginLeft: 5,
    font: 'Roboto',
    fontWeight: 400,
  },
}))

export default withScroller(Economics)
