// Third Party
import i18n, { type ResourceKey } from 'i18next'
import { initReactI18next } from 'react-i18next'

import ar from './locales/ar.json'
import bg from './locales/bg.json'
// Assets
import en from './locales/en.json'
import es from './locales/es.json'
import ht from './locales/ht.json'
import ptBr from './locales/pt-br.json'
import pt from './locales/pt.json'
import ro from './locales/ro.json'
import sk from './locales/sk.json'
// Project
import { type PlatformLanguage } from './utils/types/i18n'

// the translations
// (tip move them in a JSON file and import them)
const resources: Record<PlatformLanguage, { translation: ResourceKey }> = {
  en: { translation: en },
  es: { translation: es },
  'pt-BR': { translation: ptBr },
  pt: { translation: pt },
  ht: { translation: ht },
  sk: { translation: sk },
  bg: { translation: bg },
  ro: { translation: ro },
  ar: { translation: ar },
}

void i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('language') ?? 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
