import { type CSSProperties } from 'react'

import { ArrowBack, ArrowForward, Close } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { useLocation, useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { updateBackupUser } from '~/redux/slices/backupUser'
import { updateUser } from '~/redux/slices/user'
import { useRole } from '~/utils/hooks/useRole'
import { ROLES } from '~/utils/role-utils'

import LeaveModal from './LeaveModal'
import SaveDraftModal from './SaveDraftModal'
import { MUIWrapperContext } from './providers/MuiWrapperProvider'

interface NavIconsProps {
  title?: string
  style?: CSSProperties
}

export default function NavIcons({ title, style }: NavIconsProps) {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()

  const { t } = useTranslation()
  const { userHasAnyRole } = useRole()

  const { currentDirection } = useContext(MUIWrapperContext)

  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })
  const backupUser = useAppSelector(state => state.backupUser)
  const currentDraft = useAppSelector(state => state.currentDraft)
  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const [showLeaveModal, setShowLeaveModal] = useState(false)
  const hasSurveyDisclaimer = !!currentSurvey.disclaimer

  const isOnDisclaimerPage = location.pathname === '/lifemap/disclaimer'
  const isOnTermsPage = location.pathname === '/lifemap/terms'

  const swapBackupUserToMainUser = useCallback(() => {
    if (backupUser) {
      dispatch(updateUser(backupUser))
      dispatch(updateBackupUser(null))
    }
  }, [backupUser, dispatch])

  const canSaveDraft = useMemo(() => {
    if (!currentDraft) return false
    const { familyMembersList } = currentDraft.familyData
    const isSurveyTaker = userHasAnyRole([ROLES.SURVEY_TAKER])

    const hasUserFirstName = !!familyMembersList[0].firstName
    const hasUserLastName = !!familyMembersList[0].lastName

    if (currentSurvey.surveyConfig.isSurveyAnonymous) {
      // If the user has geolocation data during an anonymous survey. They can save drafts
      const canSaveDraft = !!currentDraft.familyData.country
      return canSaveDraft
    }

    const canSaveDraft = hasUserFirstName && hasUserLastName && !isSurveyTaker
    return canSaveDraft
  }, [currentDraft, currentSurvey, userHasAnyRole])

  function leaveSurvey() {
    mixpanel.track('leave_survey', {
      env: user.env,
      role: user.role,
      draft: currentDraft,
      user: user.username,
      survey: currentSurvey.title,
    })

    swapBackupUserToMainUser()

    if (user.dynamicLink) {
      navigate('/welcome')
      return
    }

    if (userHasAnyRole([ROLES.FAMILY])) {
      navigate('/my-profile')
      return
    }

    if (currentDraft?.isRetake) {
      const familyId = currentDraft.familyData.familyId
      navigate(`/family/${familyId}`)
      return
    }

    navigate('/surveys')
  }

  return (
    <>
      <Box
        sx={{
          p: 4,
          top: 0,
          left: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          position: 'relative',
          justifyContent: 'space-between',
        }}
        style={style}
      >
        <IconButton
          onClick={() => {
            if (
              (hasSurveyDisclaimer && isOnDisclaimerPage) ||
              (!hasSurveyDisclaimer && isOnTermsPage)
            ) {
              setShowLeaveModal(true)
              return
            }

            navigate(-1)
          }}
          color="primary"
        >
          {currentDirection === 'ltr' ? <ArrowBack /> : <ArrowForward />}
        </IconButton>

        <h2>{title}</h2>
        <IconButton
          color="primary"
          onClick={() => {
            setShowLeaveModal(true)
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <LeaveModal
        title="Warning!"
        subtitle={t('views.modals.yourLifemapIsNotComplete')}
        cancelButtonText={t('general.no')}
        continueButtonText={t('general.yes')}
        onClose={() => {
          setShowLeaveModal(false)
        }}
        open={showLeaveModal && !canSaveDraft}
        leaveAction={leaveSurvey}
      />
      <SaveDraftModal
        open={showLeaveModal && canSaveDraft}
        onClose={() => {
          setShowLeaveModal(false)
        }}
      />
    </>
  )
}
