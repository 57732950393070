import { get, isArray } from 'lodash'
import moment from 'moment'

import { type CurrentDraft } from './types/current-draft'
import { type SurveyEconomicQuestion } from './types/current-survey'

/**
 * @namespace
 * @property {string} FAMILY                - Conditional Type used for age realted conditions (for primary participant and members)
 * @property {string} SOCIOECONOMIC         - Conditional Type used for questions for all members (primary participant and members)
 * @property {string} MEMBER_SOCIOECONOMIC  - Conditional Type used for questions only for members
 */
export const CONDITION_TYPES = {
  FAMILY: 'family',
  SOCIOECONOMIC: 'socioEconomic',
  MEMBER_SOCIOEONOMIC: 'memberSocioEconomic',
}

export const JOIN_OPERATIONS = {
  AND: 'AND',
  OR: 'OR',
}

/**
 *  Returns a boolean that is the result of evaluation the condition against the question
 * @param {*} condition the condition we have to verify
 * @param {*} targetQuestion the question that holds the value we need to compare against
 */
export function evaluateCondition(condition, targetQuestion) {
  const OPERATORS = {
    EQUALS: 'equals',
    NOT_EQUALS: 'not_equals',
    LESS_THAN: 'less_than',
    GREATER_THAN: 'greater_than',
    LESS_THAN_EQ: 'less_than_eq',
    GREATER_THAN_EQ: 'greater_than_eq',
    BETWEEN: 'between',
  }
  if (!targetQuestion) {
    return false
  }

  if (condition.operator === OPERATORS.EQUALS) {
    if (moment.isMoment(targetQuestion.value)) {
      return (
        moment().diff(targetQuestion.value, 'years') === Number(condition.value)
      )
    }
    return targetQuestion.value === condition.value
  }
  if (condition.operator === OPERATORS.NOT_EQUALS) {
    if (moment.isMoment(targetQuestion.value)) {
      return (
        moment().diff(targetQuestion.value, 'years') !== Number(condition.value)
      )
    }
    return targetQuestion.value !== condition.value
  }
  if (condition.operator === OPERATORS.LESS_THAN) {
    if (moment.isMoment(targetQuestion.value)) {
      return moment().diff(targetQuestion.value, 'years') < condition.value
    }
    return targetQuestion.value < condition.value
  }
  if (condition.operator === OPERATORS.GREATER_THAN) {
    if (moment.isMoment(targetQuestion.value)) {
      return moment().diff(targetQuestion.value, 'years') > condition.value
    }
    return targetQuestion.value > condition.value
  }
  if (condition.operator === OPERATORS.LESS_THAN_EQ) {
    if (moment.isMoment(targetQuestion.value)) {
      return moment().diff(targetQuestion.value, 'years') <= condition.value
    }
    return targetQuestion.value <= condition.value
  }
  if (condition.operator === OPERATORS.GREATER_THAN_EQ) {
    if (moment.isMoment(targetQuestion.value)) {
      return moment().diff(targetQuestion.value, 'years') >= condition.value
    }
    return targetQuestion.value >= condition.value
  }
  return false
}

/**
 * Checks if the condition is met given the condition and the current state of
 * the draft
 * @param {*} condition the condition object
 * @param {*} currentDraft draft object from redux state
 * @param {*} memberIndex the index of the member inside the family data
 */
export function conditionMet(condition, currentDraft, memberIndex) {
  const socioEconomicAnswers = currentDraft.economicSurveyDataList || []
  const { familyMembersList } = structuredClone(currentDraft.familyData)
  let targetQuestion: any | null = null
  // Adding this to support backwards compatibility
  const scope = condition.scope || condition.type
  if (scope === CONDITION_TYPES.SOCIOECONOMIC) {
    // In this case target should be located in the socioeconomic answers
    targetQuestion = socioEconomicAnswers.find(
      element => element.key === condition.codeName,
    )
  } else if (scope === CONDITION_TYPES.FAMILY) {
    const familyMember = familyMembersList[memberIndex]
    // TODO HARDCODED FOR IRRADIA. WE NEED A BETTER WAY TO SPECIFY THAT THE CONDITION
    // HAS BEEN MADE ON A DATE
    // const value = familyMember[condition.codeName]
    //   ? moment.unix(familyMember[condition.codeName])
    //   : null;
    // TODO hardcoded for Irradia, the survey has an error with the field.
    // The lines above should be used once data is fixed for that survey
    let value
    if (condition.codeName.toLowerCase() === 'birthdate') {
      value = familyMember.birthDate
        ? moment.unix(familyMember.birthDate)
        : null
      // TODO DELETE THIS after reviewing the conditional logic
      // In case the target question is null, we should return true.
      // Eventually, the conditional object should include information about that
      // and delete this hard-coding
      if (!value) {
        // Now we have a proper feature of showIfNoData. Keeping this
        // hardcode just for backwards compatibility for IRRADIA.
        return true
      }
    } else {
      value = familyMember[condition.codeName]
    }
    targetQuestion = { value }
  } else if (scope === CONDITION_TYPES.MEMBER_SOCIOEONOMIC) {
    const { socioEconomicAnswers: memberSocioEconomicAnswers = [] } =
      familyMembersList[memberIndex] || {}
    targetQuestion = memberSocioEconomicAnswers.find(
      element => element.key === condition.codeName,
    )
  }

  // Added support for showIfNoData. In the case this field is set to true in the
  // condition config and the target question does not have value, we show the question
  // without any further evaluation
  if (
    condition.showIfNoData &&
    (!targetQuestion || (!targetQuestion.value && targetQuestion.value !== 0))
  ) {
    return true
  }
  // Adding support for several values spec. In case we find more than one value,
  // the condition is considered to be met if the evaluation returns true for at least one
  // of the values received in the array
  if (isArray(condition.values) && condition.values.length > 0) {
    return condition.values.reduce((acc, current) => {
      return (
        acc ||
        evaluateCondition({ ...condition, value: current }, targetQuestion)
      )
    }, false)
  }
  return evaluateCondition(condition, targetQuestion)
}

const applyBooleanOperations = (op1, op2, operator) =>
  operator === JOIN_OPERATIONS.AND ? op1 && op2 : op1 || op2

/**
 * Decides whether a question should be shown to the user or not
 * @param question the question we want to know if can be shown
 * @param currentDraft the draft from redux state
 * @param memberIndex the index of the member in the store
 */
export function shouldShowQuestion(
  question: SurveyEconomicQuestion,
  currentDraft: CurrentDraft,
  memberIndex?: number,
) {
  let shouldShow = true
  if (question.conditionGroups && question.conditionGroups.length > 0) {
    ;({ result: shouldShow } = question.conditionGroups.reduce(
      (acc, current) => {
        const { conditions, groupOperator, joinNextGroup } = current
        const groupResult = conditions.reduce(
          (accGroup, currentFromGroup) =>
            applyBooleanOperations(
              accGroup,
              conditionMet(currentFromGroup, currentDraft, memberIndex),
              groupOperator,
            ),
          groupOperator === JOIN_OPERATIONS.AND,
        )
        return {
          result: applyBooleanOperations(acc.result, groupResult, acc.joinPrev),
          joinPrev: joinNextGroup,
        }
      },
      { result: true, joinPrev: JOIN_OPERATIONS.AND },
    ))
  } else if (question.conditions && question.conditions.length > 0) {
    shouldShow = question.conditions.reduce(
      (acc, current) => acc && conditionMet(current, currentDraft, memberIndex),
      true,
    )
  }
  return shouldShow
}

/**
 * This function is used so we can know beforehand if the family member
 * meets the condition for at least one of the questions
 * @param {*} questions the questions for each family member.
 * @param {*} currentDraft the draft from redux state
 * @param {*} memberIndex the index of the member in the store
 */
export function familyMemberWillHaveQuestions(
  questions,
  currentDraft,
  memberIndex,
) {
  return questions.forFamilyMember.reduce(
    (acc, current) =>
      acc || shouldShowQuestion(current, currentDraft, memberIndex),
    false,
  )
}

/**
 * Filters the options that are going to be displayed for a question
 * for the case when they're conditional
 * @param question the question that has the options to filter
 * @param currentDraft the draft from the redux store
 * @param index the index of the family member
 */
export function getConditionalOptions(
  question,
  currentDraft: CurrentDraft,
  index?: number,
) {
  return question.options.filter(option =>
    shouldShowQuestion(option, currentDraft, index),
  )
}

/**
 * Returns a boolean indicating if the value of the conditionalQuestion should be cleaned up.
 * @param {*} conditionalQuestion the question whose answer we'll check if should be cleaned up
 * @param {*} currentDraft the draft from the redux store
 * @param {*} member
 * @param {*} memberIndex
 */
export function shouldCleanUp(
  conditionalQuestion,
  currentDraft,
  member?,
  memberIndex?,
) {
  let currentAnswer
  if (conditionalQuestion.forFamilyMember) {
    currentAnswer = get(member, 'socioEconomicAnswers', []).find(
      ea => ea.key === conditionalQuestion.codeName,
    )
  } else {
    currentAnswer = get(currentDraft, 'economicSurveyDataList', []).find(
      ea => ea.key === conditionalQuestion.codeName,
    )
  }
  if (
    !currentAnswer ||
    (!currentAnswer.value &&
      (!currentAnswer.multipleValue ||
        currentAnswer.multipleValue.length === 0))
  ) {
    // There's nothing to cleanUp, user has not answered the question yet
    // console.log(
    //   `Nothing to cleanUp for conditionalQuestion ${
    //     conditionalQuestion.codeName
    //   }`
    // );
    return false
  }
  let cleanUp = false
  if (
    conditionalQuestion.conditions &&
    conditionalQuestion.conditions.length > 0
  ) {
    cleanUp = !shouldShowQuestion(
      conditionalQuestion,
      currentDraft,
      memberIndex,
    )
  }
  if (
    !cleanUp &&
    conditionalQuestion.options &&
    conditionalQuestion.options.length > 0
  ) {
    // Putting this in an if block so we don't check cleaning up for unavailable selected option
    // in the case it's already decided we have to clean
    // Verifying if current value is not present among the filtered conditional
    // options, in which case we'll need to cleanup
    const availableOptions = getConditionalOptions(
      conditionalQuestion,
      currentDraft,
      memberIndex,
    )
    // Check if a single chosed option it's still available
    cleanUp = !availableOptions.find(
      option => option.value === currentAnswer.value,
    )

    // Check if all choosen option are still available in case of a multiValue question
    if (
      !!currentAnswer.multipleValue &&
      currentAnswer.multipleValue.length > 0
    ) {
      cleanUp = currentAnswer.multipleValue.reduce(
        (result, value) =>
          result || !availableOptions.find(option => option.value === value),
        false,
      )
    }
  }
  if (cleanUp) {
    console.log(
      `CleanUp needed for conditionalQuestion ${conditionalQuestion.codeName} member ${memberIndex}`,
    )
  }

  return cleanUp
}

/**
 * Returns a new draft that contains an new/updated economic answer
 * @param {*} currentDraft the current draft
 * @param {*} economicAnswer the new answer for some economic question
 */
export function getDraftWithUpdatedEconomic(currentDraft, economicAnswer) {
  const { economicSurveyDataList = [] } = currentDraft
  const updatedEconomics = [...economicSurveyDataList]
  const answerToUpdateIndex = updatedEconomics.findIndex(
    a => a.key === economicAnswer.key,
  )
  if (answerToUpdateIndex !== -1) {
    updatedEconomics[answerToUpdateIndex] = economicAnswer
  } else {
    updatedEconomics.push(economicAnswer)
  }
  return { ...currentDraft, economicSurveyDataList: updatedEconomics }
}

/**
 * Returns a new draft that contains an new/updated economic answer for a family member
 * @param currentDraft the current draft
 * @param economicAnswer the new answer for some economic per member question
 * @param index index of the member whose economic are going to be generated
 */
export function getDraftWithUpdatedFamilyEconomics(
  currentDraft: CurrentDraft,
  economicAnswer,
  index: number,
) {
  const { familyMembersList } = currentDraft.familyData
  const updatedFamilyMembersList = [...familyMembersList]
  const { socioEconomicAnswers = [] } = updatedFamilyMembersList[index]
  const updatedEconomics = [...socioEconomicAnswers]
  updatedFamilyMembersList[index] = {
    ...updatedFamilyMembersList[index],
    socioEconomicAnswers: updatedEconomics,
  }
  const answerToUpdateIndex = updatedEconomics.findIndex(
    a => a.key === economicAnswer.key,
  )

  if (answerToUpdateIndex !== -1) {
    updatedEconomics[answerToUpdateIndex] = economicAnswer
  } else {
    updatedEconomics.push(economicAnswer)
  }
  return {
    ...currentDraft,
    familyData: {
      ...currentDraft.familyData,
      familyMembersList: updatedFamilyMembersList,
    },
  }
}

/**
 * Returns a new draft that contains updated member data
 * @param currentDraft the current draft
 * @param field field of the member to update
 * @param value new value for the provided field
 * @param economicAnswer the new answer for some economic per member question
 */
export function getDraftWithUpdatedMember(
  currentDraft: CurrentDraft,
  field: string,
  value,
  index: number,
) {
  const { familyMembersList } = currentDraft.familyData
  const updatedFamilyMembersList = [...familyMembersList]
  const updatedFamilyMember = { ...familyMembersList[index] }
  updatedFamilyMember[field] = value
  updatedFamilyMembersList[index] = updatedFamilyMember
  return {
    ...currentDraft,
    familyData: {
      ...currentDraft.familyData,
      familyMembersList: updatedFamilyMembersList,
    },
  }
}

/**
 * Returns a new draft that has applied conditional logic cascaded to all
 * questions with conditionals or with conditionals options
 */
export function getDraftWithUpdatedQuestionsCascading(
  draft: CurrentDraft,
  conditionalQuestions,
  cleanupHook?,
) {
  let currentDraft = { ...draft }
  conditionalQuestions.forEach(conditionalQuestion => {
    const keyName =
      conditionalQuestion.answerType === 'checkbox' ? 'multipleValue' : 'value'
    const keyValue = conditionalQuestion.answerType === 'checkbox' ? [] : ''
    const cleanedAnswer = {
      key: conditionalQuestion.codeName,
      [keyName]: keyValue,
    }
    if (conditionalQuestion.forFamilyMember) {
      // Checking if we have to cleanup familyMembers socioeconomic answers
      currentDraft.familyData.familyMembersList.forEach((member, index) => {
        if (shouldCleanUp(conditionalQuestion, currentDraft, member, index)) {
          // Cleaning up socioeconomic answer for family member
          currentDraft = getDraftWithUpdatedFamilyEconomics(
            currentDraft,
            cleanedAnswer,
            index,
          )
          // If provided, calls the cleanupHook for the question that has been cleaned up
          if (cleanupHook) {
            cleanupHook(conditionalQuestion, index)
          }
        }
      })
    } else if (shouldCleanUp(conditionalQuestion, currentDraft)) {
      // Cleaning up socioeconomic answer
      currentDraft = getDraftWithUpdatedEconomic(currentDraft, cleanedAnswer)
      // If provided, calls the cleanupHook for the question that has been cleaned up
      if (cleanupHook) {
        cleanupHook(conditionalQuestion)
      }
    }
  })

  return currentDraft
}
