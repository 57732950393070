import { Cancel, Check } from '@mui/icons-material'
import {
  Button,
  ButtonGroup,
  Container,
  Stack,
  Typography,
} from '@mui/material'
import mixpanel from 'mixpanel-browser'
import { useLocation, useNavigate } from 'react-router-dom'

import BottomSpacer from '~/components/BottomSpacer'
import LeaveModal from '~/components/LeaveModal'
import NoProdWarningBanner from '~/components/NoProdWarningBanner'
import TitleBar from '~/components/TitleBar'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { prepareDemoDraft, prepareDraft } from '~/redux/slices/currentDraft'
import { callingCodes } from '~/utils/calling-codes'

export default function Terms() {
  const location = useLocation()
  const navigate = useNavigate()
  const isInTermsPage = location.pathname === '/lifemap/terms'

  const { t } = useTranslation()
  const [canShowLeaveModal, setCanShowLeaveModal] = useState(false)

  const dispatch = useAppDispatch()

  const currentDraft = useAppSelector(state => state.currentDraft)
  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No survey')
    return state.currentSurvey
  })
  const user = useAppSelector(state => {
    if (!state.user) throw new Error('No user')
    return state.user
  })

  const title = isInTermsPage
    ? currentSurvey.termsConditions.title
    : currentSurvey.privacyPolicy.title

  const text = isInTermsPage
    ? currentSurvey.termsConditions.text
    : currentSurvey.privacyPolicy.text

  function leaveSurvey() {
    mixpanel.track('leave_survey', {
      env: user.env,
      role: user.role,
      draft: currentDraft,
      user: user.username,
      survey: currentSurvey.title,
    })

    if (user.dynamicLink) {
      navigate('/welcome')
      return
    }

    if (currentDraft?.isRetake) {
      const familyId = currentDraft.familyData.familyId
      navigate(`/family/${familyId}`)
      return
    }

    navigate('/surveys')
  }

  function handleContinue() {
    window.onbeforeunload = () => null

    const projectId = location.state?.projectId ?? null
    const isAnonymous = currentSurvey.surveyConfig.isSurveyAnonymous
    const isJustSToplight =
      currentDraft?.justStoplight ?? currentDraft?.justStoplightRetake ?? false

    if (isInTermsPage) {
      navigate('/lifemap/privacy', { state: { projectId } })
      return
    }

    if (!isInTermsPage) {
      const isDemoSurvey = currentSurvey.surveyConfig.isDemo ?? false
      // NOTE: Phone code for Canada is the same as US (+1)
      const countryCode =
        currentSurvey.surveyConfig.surveyLocation.country === 'CA'
          ? 'US'
          : currentSurvey.surveyConfig.surveyLocation.country

      const phoneCode =
        callingCodes.find(e => e.code === countryCode) ?? callingCodes[0]

      if (!currentDraft) {
        if (isDemoSurvey && !isAnonymous) {
          dispatch(
            prepareDemoDraft({
              projectId,
              surveyId: currentSurvey.id,
              surveyVersionId: currentSurvey.surveyVersionId ?? null,
              phoneCode: phoneCode?.value,
              currentLocation: location.pathname,
              documentTypeValues: currentSurvey.surveyConfig.documentType.map(
                gender => gender.value,
              ),
              phoneCodeValues: callingCodes.map(code => code.value),
            }),
          )
        }

        if (!isDemoSurvey || isAnonymous) {
          dispatch(
            prepareDraft({
              projectId,
              surveyId: currentSurvey.id,
              surveyVersionId: currentSurvey.surveyVersionId ?? null,
              countryCode,
              phoneCode: phoneCode?.value,
              currentLocation: location.pathname,
            }),
          )
        }
      }

      if (isJustSToplight) {
        navigate('/lifemap/stoplight/0', {
          state: { projectId, agreedTerms: true },
        })
        return
      }

      if (
        currentSurvey.surveyConfig.shouldCreateUserBeforeSurveyFlow &&
        !currentDraft?.isRetake
      ) {
        navigate('/lifemap/create-user', {
          state: { projectId, agreedTerms: true },
        })
        return
      }

      if (isAnonymous) {
        navigate('/lifemap/location', {
          state: { projectId, agreedTerms: true },
        })
        return
      }
    }

    navigate('/lifemap/primary-participant', {
      state: { projectId, agreedTerms: true },
    })
  }

  return (
    <>
      <LeaveModal
        title="Warning!"
        open={canShowLeaveModal}
        leaveAction={leaveSurvey}
        cancelButtonText={t('general.no')}
        continueButtonText={t('general.yes')}
        subtitle={t('views.modals.yourLifemapIsNotComplete')}
        onClose={() => {
          setCanShowLeaveModal(false)
        }}
      />

      <TitleBar
        title={
          isInTermsPage ? t('views.termsConditions') : t('views.privacyPolicy')
        }
      />

      <Container maxWidth="md">
        <Stack py={2}>
          <NoProdWarningBanner />
          <Typography dir="auto" variant="h5">
            {title}
          </Typography>
          <br />
          {text?.split(/(?:\\n)/g).map((i, idx) => (
            <Typography
              key={idx}
              dir="auto"
              color="textPrimary"
              textAlign="justify"
              sx={{ fontSize: 18 }}
            >
              {i}
              <br />
            </Typography>
          ))}
          <Stack
            py={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
          >
            <ButtonGroup>
              <Button
                startIcon={<Cancel />}
                onClick={() => {
                  setCanShowLeaveModal(true)
                }}
              >
                {t('general.disagree')}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={handleContinue}
                endIcon={<Check />}
              >
                {t('general.agree')}
              </Button>
            </ButtonGroup>
          </Stack>
        </Stack>
      </Container>
      <BottomSpacer />
    </>
  )
}
