import {
  MyLocation as MyLocationIcon,
  Search as SearchIcon,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Fab,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  AdvancedMarker,
  ControlPosition,
  Map,
  MapControl,
  useMap,
} from '@vis.gl/react-google-maps'
import { useSnackbar } from 'notistack'
import Geocode from 'react-geocode'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { useNavigate } from 'react-router-dom'

import MarkerIcon from '~/assets/marker.png'
import TitleBar from '~/components/TitleBar'
import { useAppDispatch, useAppSelector } from '~/redux/hooks'
import { setTimersByScreen, updateDraft } from '~/redux/slices/currentDraft'
import {
  normalizeDraftForSnapshot,
  saveSnapshotDraft,
} from '~/services/lifemap-survey-services'
import { useCurrentPosition } from '~/utils/hooks/useCurrentPosition'
import { screenValidation } from '~/utils/survey-utils'

Geocode.setApiKey(import.meta.env.VITE_MAP_API_KEY)

export default function Location() {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const map = useMap()

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const { gettingCurrentPosition, getCurrentPosition } = useCurrentPosition()

  const currentDraft = useAppSelector(state => {
    if (!state.currentDraft) throw new Error('No currentDraft')
    return state.currentDraft
  })
  const currentSurvey = useAppSelector(state => {
    if (!state.currentSurvey) throw new Error('No currentSurvey')
    return state.currentSurvey
  })

  const [address, setAddress] = useState('')
  const parsedLat = Number(currentDraft.familyData.latitude)
  const parsedLng = Number(currentDraft.familyData.longitude)

  const defaultPosition: google.maps.LatLngLiteral = {
    lat: Number.isNaN(parsedLat)
      ? currentSurvey.surveyConfig.surveyLocation.latitude
      : parsedLat,
    lng: Number.isNaN(parsedLng)
      ? currentSurvey.surveyConfig.surveyLocation.longitude
      : parsedLng,
  }

  const [position, setPosition] = useState(defaultPosition)

  async function handleContinue() {
    const country = await getCountryFromLatLng(position.lat, position.lng)

    const updatedDraft = {
      ...currentDraft,
      familyData: {
        ...currentDraft.familyData,
        latitude: position.lat,
        longitude: position.lng,
        ...(country ? { country } : {}),
      },
    }

    dispatch(updateDraft(updatedDraft))

    const normalizedDraft = normalizeDraftForSnapshot(updatedDraft)
    void saveSnapshotDraft(normalizedDraft)

    if (!currentSurvey.economicScreens?.questionsPerScreen.length) {
      navigate('/lifemap/begin-stoplight')
      return
    }

    navigate('/lifemap/economics/0')
  }

  async function handleSelect(address: string) {
    const results = await geocodeByAddress(address)
    const latLng = await getLatLng(results[0])

    setAddress(address)
    setPosition(previousState => ({
      ...previousState,
      lat: latLng.lat,
      lng: latLng.lng,
    }))

    if (!map) throw new Error('No map')
    map.panTo(latLng)

    const country = await getCountryFromLatLng(latLng.lat, latLng.lng)

    dispatch(
      updateDraft({
        ...currentDraft,
        familyData: {
          ...currentDraft.familyData,
          latitude: latLng.lat,
          longitude: latLng.lng,
          ...(country ? { country } : {}),
        },
      }),
    )
  }

  async function getCountryFromLatLng(lat: number, lng: number) {
    try {
      const { results } = await Geocode.fromLatLng(
        lat.toString(),
        lng.toString(),
      )
      if (!results?.length) return null

      const element = results.find(result => result.types.includes('country'))
      if (!element) return null

      return element.address_components[0].short_name as string
    } catch (error) {
      console.error(error)
      return null
    }
  }

  async function onDragMapEnded() {
    if (!map) throw new Error('No map')
    const center = map.getCenter()
    if (!center) throw new Error('No center')

    setPosition(previousState => ({
      ...previousState,
      lat: center.lat(),
      lng: center.lng(),
    }))

    const country = await getCountryFromLatLng(center.lat(), center.lng())

    dispatch(
      updateDraft({
        ...currentDraft,
        familyData: {
          ...currentDraft.familyData,
          latitude: center.lat(),
          longitude: center.lng(),
          ...(country ? { country } : {}),
        },
      }),
    )
  }

  const locateMe = useCallback(async () => {
    try {
      const currentPosition = await getCurrentPosition()

      const lat = currentPosition.coords.latitude
      const lng = currentPosition.coords.longitude

      setPosition(previousState => ({ ...previousState, lat, lng }))

      if (!map) throw new Error('No map')
      map.panTo({ lat, lng })

      const country = await getCountryFromLatLng(lat, lng)

      dispatch(
        updateDraft({
          ...currentDraft,
          familyData: {
            ...currentDraft.familyData,
            latitude: lat,
            longitude: lng,
            ...(country ? { country } : {}),
          },
        }),
      )
    } catch (error) {
      const isGeolocationError = error instanceof GeolocationPositionError
      if (!isGeolocationError) {
        return
      }

      switch (error.code) {
        case GeolocationPositionError.PERMISSION_DENIED:
          enqueueSnackbar(t('views.location.permissionDisabled'), {
            variant: 'error',
          })
          break
        case GeolocationPositionError.POSITION_UNAVAILABLE:
        case GeolocationPositionError.TIMEOUT:
          enqueueSnackbar(t('views.location.positionUnavailable'), {
            variant: 'error',
          })
          break
      }
    }
  }, [getCurrentPosition, map, dispatch, currentDraft, enqueueSnackbar, t])

  useEffect(() => {
    const validationResult = screenValidation(
      currentDraft,
      'LOCATION',
      currentSurvey,
    )

    if (validationResult) {
      enqueueSnackbar(t('validation.completionRequired'), { variant: 'error' })
      navigate(validationResult)
    }
  }, [currentDraft, enqueueSnackbar, navigate, t, currentSurvey])

  useEffect(() => {
    dispatch(setTimersByScreen('Location'))
  }, [dispatch])

  useEffect(() => {
    if (
      !currentDraft.familyData.latitude ||
      !currentDraft.familyData.longitude
    ) {
      void locateMe()
    }
  }, [currentDraft, locateMe])

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <TitleBar title={t('views.location.title')} progressBar />

      <Box sx={{ flexGrow: 1 }}>
        <PlacesAutocomplete
          value={address}
          onChange={address => {
            setAddress(address)
          }}
          onSelect={async address => {
            await handleSelect(address)
          }}
          onError={error => {
            console.error(error)
          }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <Map
              mapId="164d8681d71b9cae"
              defaultZoom={18}
              defaultCenter={defaultPosition}
              style={{ height: '100%' }}
              clickableIcons={false}
              mapTypeControl={false}
              fullscreenControl={false}
              streetViewControl={false}
              zoomControlOptions={{
                position: google.maps.ControlPosition.RIGHT_TOP,
              }}
              onDrag={event => {
                const center = event.map.getCenter()
                if (!center) return
                setPosition(previousState => ({
                  ...previousState,
                  ...center.toJSON(),
                }))
              }}
              onDragend={onDragMapEnded}
            >
              <AdvancedMarker position={position}>
                <img
                  src={MarkerIcon}
                  style={{ width: '40px', userSelect: 'none' }}
                />
              </AdvancedMarker>

              <MapControl position={ControlPosition.LEFT_BOTTOM}>
                <Fab
                  sx={{ marginLeft: '20px', marginBottom: '14px' }}
                  onClick={async () => {
                    await locateMe()
                  }}
                  color="primary"
                  size="medium"
                  disabled={gettingCurrentPosition}
                >
                  <MyLocationIcon />
                </Fab>
              </MapControl>

              <MapControl position={ControlPosition.TOP_LEFT}>
                <Box
                  sx={{
                    width: { xs: '300px', sm: '400px' },
                    marginTop: '10px',
                    marginLeft: '10px',
                  }}
                >
                  <Paper
                    sx={{
                      padding: '2px 4px',
                      display: 'flex',
                      alignItems: 'center',
                      bgcolor: 'white',
                    }}
                  >
                    <IconButton
                      disabled
                      sx={{ p: '10px' }}
                      aria-label="Search"
                      size="large"
                    >
                      <SearchIcon />
                    </IconButton>
                    <InputBase
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputBase,
                      }}
                      {...getInputProps({
                        placeholder: t('views.location.searchBoxPlaceholder'),
                      })}
                    />
                  </Paper>
                  <Box sx={{ bgcolor: 'white' }}>
                    {loading && (
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={t('views.location.loadingOptionsLabel')}
                            primaryTypographyProps={{
                              className: classes.suggestionsTypography,
                            }}
                          />
                        </ListItem>
                      </List>
                    )}
                    {!loading && suggestions && suggestions.length > 0 && (
                      <List>
                        {suggestions.map(suggestion => (
                          <ListItem key={suggestion.description}>
                            <Box {...getSuggestionItemProps(suggestion)}>
                              <ListItemText
                                primary={suggestion.description}
                                primaryTypographyProps={{
                                  className: classes.suggestionsTypography,
                                }}
                              />
                            </Box>
                          </ListItem>
                        ))}
                      </List>
                    )}
                  </Box>
                </Box>
              </MapControl>

              <MapControl position={ControlPosition.BOTTOM_CENTER}>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  onClick={async () => {
                    await handleContinue()
                  }}
                  disabled={
                    !currentDraft.familyData.latitude ||
                    !currentDraft.familyData.longitude ||
                    gettingCurrentPosition
                  }
                  sx={{ mb: '40px' }}
                  loading={gettingCurrentPosition}
                  size="large"
                >
                  {t('general.continue')}
                </LoadingButton>
              </MapControl>
            </Map>
          )}
        </PlacesAutocomplete>
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(_theme => ({
  suggestionsTypography: { fontSize: 14 },
  inputRoot: {
    marginLeft: 8,
    flex: 1,
    fontSize: '14px',
  },
  inputBase: {
    padding: '1px !important',
  },
}))
